
import cardClick from "~/mixins/cardClick"
import filters from "~/mixins/filters"

export default {
	mixins: [filters, cardClick],
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		choose: {
			type: Boolean,
			default: false
		},
		hasButtons: {
			type: Boolean,
			default: true
		},
		slide: {
			type: Boolean,
			default: false
		},
		offer: Object
	},

}
